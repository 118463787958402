import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

const ContentCol = ({ children }) => {
  return (
    <Col xs={11} sm={9} md={8} lg={7}>
      {children}
    </Col>
  );
};

ContentCol.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentCol;
