import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container, Row } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import ReactPlayer from 'react-player';

import ContentCol from '../contentCol/contentCol';
import Styles from './chatBanner.styles';

const ChatBanner = () => {
  let [shouldPlay, updatePlayState] = useState(false);

  let handleEnterViewport = function () {
    updatePlayState(true);
  };
  let handleExitViewport = function () {
    updatePlayState(false);
  };

  return (
    <Waypoint onEnter={handleEnterViewport} onLeave={handleExitViewport}>
      <Styles>
        <Container>
          <Row className="justify-content-center text-center">
            <ContentCol>
              <h2>See what it&apos;s like to work with us.</h2>
              <p className="d-block my-4">
                We're on the journey with our partners. Tell us where you're
                headed, and we'll give you 30 minutes of the same thoughtful,
                strategic guidance our clients enjoy.
              </p>
              <Link to="/office-hours/" className="btn btn-info">
                Let's Talk
              </Link>
            </ContentCol>
          </Row>
        </Container>
        <ReactPlayer
          className="background"
          url="/bg-footer.mp4"
          playing={shouldPlay}
        />
      </Styles>
    </Waypoint>
  );
};

export default ChatBanner;
