import styled from '@emotion/styled';
import breakpoints from '../../utils/responsive';
import rhythm from '../../utils/rhythm';
import colors from '../../styles/modules/colors.module.scss';

const Styles = styled.div({
  color: colors.white,
  position: 'relative',
  overflow: 'hidden',

  '.container': {
    zIndex: '1',
  },

  'h2, p, a': {
    color: colors.white,
  },

  h2: {
    fontSize: `${rhythm(3)}`,
    lineHeight: `${rhythm(3)}`,
  },

  small: {
    display: 'block',
    marginTop: `${rhythm(1)}`,
  },

  '.background': {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100% !important',
    height: '100% !important',
    backgroundColor: colors.black,
    zIndex: '-1',

    video: {
      opacity: '0.4',
      position: 'absolute',
      top: '75%',
      left: '50%',
      minWidth: '100%',
      minHeight: '100%',
      width: 'auto !important',
      height: 'auto !important',
      transform: 'translateX(-50%) translateY(-50%)',
    },
  },

  [breakpoints.Mobile]: {
    '.container': {
      paddingTop: `${rhythm(5)}`,
      paddingBottom: `${rhythm(5)}`,
    },

    h2: {
      fontSize: `${rhythm(2)}`,
      lineHeight: `${rhythm(2)}`,
    },
  },

  [breakpoints.Tablet]: {
    '.container': {
      paddingTop: `${rhythm(6)}`,
      paddingBottom: `${rhythm(6)}`,
    },

    h2: {
      fontSize: `${rhythm(2)}`,
      lineHeight: `${rhythm(2)}`,
    },
  },

  [breakpoints.Desktop]: {
    '.background': {
      video: {
        top: '50%',
      },
    },
  },
});

export default Styles;
